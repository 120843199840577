import React from "react";
import { Card } from "react-bootstrap";
import "./Cds.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import parse from 'html-react-parser';
import kidneySymbolImage from './assets/nyre.png';

export const CdsKidneyComponent = ({ kidneyResponseData, patientAge, closeCardKidney, showKidneyCard }) => {
   console.log('CdsResponseData received:', kidneyResponseData);

   const ageSensitiveSummaries = [
    "<div xmlns=\"http://www.w3.org/1999/xhtml\">TCA (trisykliske antidepressiver)</div>",
    "<div xmlns=\"http://www.w3.org/1999/xhtml\">Antipsykotika (nevroleptika)</div>",
    "<div xmlns=\"http://www.w3.org/1999/xhtml\">Antihistaminer – 1. generasjon</div>",
    "<div xmlns=\"http://www.w3.org/1999/xhtml\">Antikolinerge midler mot overaktiv blære</div>",
    "<div xmlns=\"http://www.w3.org/1999/xhtml\">Sulfonylurea</div>"
  ];

  if (!kidneyResponseData || !Array.isArray(kidneyResponseData.cards)) {
    return null;
  }

  console.log('Pasientalder:', patientAge);

  const filteredCards = kidneyResponseData.cards.filter(card => {
    const isAgeSensitive = ageSensitiveSummaries.some(summary => card.summary.includes(summary));
    return patientAge > 65 ? true : !isAgeSensitive;
  });

  return (
    <div>
      {showKidneyCard && filteredCards.map((cdsCard, idx) => {
        const isAgeSensitiveCard = ageSensitiveSummaries.some(summary =>
          cdsCard.summary.includes(summary)
        );

        const category = cdsCard.extension["no.felleskatalogen.cds.nedsatt-nyre.kategori"];
        const subcategory = cdsCard.extension["no.felleskatalogen.cds.nedsatt-nyre.underkategori"];

        const cardHeader = isAgeSensitiveCard && patientAge > 65
          ? "Legemiddelet bør unngås da pasienten er over 65 år"
          : `${category} - ${subcategory}`;

        // Extract only the title-criteria content
        const parser = new DOMParser();
        const doc = parser.parseFromString(cdsCard.summary, 'text/html');
        const titleCriteria = doc.querySelector('.title-criteria')?.textContent || "Legemiddel";

        return (
          <Card className="cdsModal" key={`cds-card-${idx}`}>
            <Card.Header>
              <img src={kidneySymbolImage} alt="Nyresymbol" className="text-kidney4" />
              <span>{cardHeader}</span>
              <button onClick={closeCardKidney} className="close-button">
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </Card.Header>
            <Card.Body className="cdsResponseBody">
              {/* Display only the title-criteria content as the main title */}
              <h4 className="cdsResponseMainTitle">{titleCriteria}</h4>
              
              {/* Display the details */}
              {cdsCard.detail && <div className="cdsResponseDetail">{parse(cdsCard.detail)}</div>}
              
              <div className="cdsResponseFooter">
                <div className="cdsResponseProvider">
                  <span>Tjenesten leveres av</span> 
                  <a href={cdsCard.source.url} 
                     target="_blank" 
                     rel="noopener noreferrer"
                     className="cdsResponseLink">
                    {cdsCard.source.label}
                  </a>
                </div>
              </div>
            </Card.Body>
          </Card>
        );
      })}
    </div>
  );
};

export default CdsKidneyComponent;
