import React, { useState } from "react"
import { useEffect } from 'react'
import "./AutoComplete.css"
import "bootstrap/dist/css/bootstrap.min.css"
import {
  Button,
  Container,
  Row,
  Col,
  FloatingLabel,
  Form,
} from "react-bootstrap"
import "react-phone-input-2/lib/style.css"
import { useNavigate, useLocation, useParams } from "react-router-dom"
import HomeIcon from "@mui/icons-material/Home"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
// import Card from "react-bootstrap/Card";
import Autosuggest from "react-autosuggest"
import Loader from "./Loader"
import { CDSCardComponent } from "./CDSCardComponent"
import {
  fhirServer,
  conceptQueryObservation,
  baseCDSServiceUrl,
  // pulseobservationURL,
  headers,
} from "../config.js"

// Anta at du har importert din units data fra JSON-filen
import unitsData from './data/units.json';
import ErrorModal from './ErrorModal';

function Observation(props) {
  const [showMoreCard, setShowMoreCard] = useState(undefined)

  const [submissionTime, setSubmissionTime] = useState(null)
  const [value, setValue] = useState("")
  const [suggestions, setSuggestions] = useState([])
  const [selectedObservation, setSelectedObservation] = useState({ unit: '' })
  const [units, setUnits] = useState(''); // Anta at dette er tilstanden som holder enheten
  const [customUnit, setCustomUnit] = useState('');
  const [formDetails, setFormDetails] = useState(undefined)
  // the line to connect pulseobservation:
  // const [pulseobservation, setPulseObservation] = useState(undefined);
  const [showJson, setShowJson] = useState(false)
  const [dataCards, setCards] = useState(null)
  // eslint-disable-next-line
  const [state, setState] = useState(false)
  const forceUpdate = () => setState((prevState) => !prevState)
  const [loader, setLoader] = useState(false)
  const [observationValue, setObservationValue] = useState(undefined)
  // const [unit, setUnit] = useState(undefined);

  // Definer tilstandsvariabler og funksjoner her
  const [error, setError] = useState(null); // Tilstand for feilmeldinger

  // Funksjon for å håndtere lukking av feilmodal
  const handleCloseErrorModal = () => setError(null);

  // Funksjon for å håndtere feil
  const handleError = (errorMessage) => setError(errorMessage);


  const callCDS = (suggestion, observationValue) => {
    setLoader(true)
    if (params) {
      Promise.all([
        fetch(fhirServer + `/Patient?_id=${params.patientId}`),
        fetch(fhirServer + `/Observation?patient=${params.patientId}`),
        fetch(fhirServer + `/MedicationRequest?patient=${params.patientId}`),
        fetch(baseCDSServiceUrl + "/cds-services?id="),
      ])
        .then((responses) =>
          Promise.all(responses.map((response) => response.json()))
        )
        .then(
          ([patientData, observationData, medicationData, servicesData]) => {
            let patientDataToUse = patientData.entry[0].resource
            let CDSHooks = servicesData.services

            if (Array.isArray(CDSHooks) && CDSHooks.length > 0) {
              console.log("observationData", observationData)
              console.log("medicationData", medicationData)

              let simulatedEntry = {
                fullUrl:
                `${fhirServer}/Observation/${params.patientId}`,
                resource: {
                  resourceType: "Observation",
                  status: "final",
                  code: {
                    coding: [
                      {
                        system: "http://snomed.info/sct",
                        code: suggestion.conceptId,
                        display: suggestion.fsn.term,
                      },
                    ],
                  },
                  valueQuantity: {
                    value: parseInt(observationValue),
                    unit: "mL/min", // change for diff observations, and not mandatory also
                    system: "http://unitsofmeasure.org",
                  },
                  effectiveDateTime: new Date(),
                  subject: { reference: `Patient/${params.patientId}` },
                },
              }

              const prepareObservationData = (
                observationData,
                simulatedEntry,
                patientId
              ) => {
                if (observationData.entry) {
                  console.log("there are entries")
                  // if there is at least one medication that was already prescribed:
                  observationData.entry.push(simulatedEntry)
                  observationData.total = observationData.entry.length
                } else {
                  console.log("no entries were found")

                  observationData = {
                    resourceType: "Bundle",
                    type: "searchset",
                    entry: [simulatedEntry],
                    total: 1,
                    link: [
                      {
                        relation: "self",
                        url: `${fhirServer}/Observation?patient=${patientId}`,
                      },
                    ],
                  }
                }

                return observationData
              }

              let observationDataToCallCDS = prepareObservationData(
                observationData,
                simulatedEntry,
                params.patientId
              )

              // CDSHooks.forEach((hook) => {
              const fetchPromises = CDSHooks.map((hook) => {
                const cdsServiceUrl = `${baseCDSServiceUrl}/cds-services?id=${hook.id}`

                // building the body to call a cds-service:
                let body = {
                  hook: "patient-view",
                  hookInstance: "e3dfbf01-8d3a-4d17-b0f0-521896f7c9e9",
                  fhirServer: `${baseCDSServiceUrl}/fhir`,
                  context: {
                    userId: "Patient/" + params.patientId,
                    patientId: params.patientId,
                  },
                  prefetch: {
                    item1: patientDataToUse,
                    item2: observationDataToCallCDS,
                    item3: medicationData,
                  },
                }

                return fetch(cdsServiceUrl, {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(body),
                })
                  .then((res) => res.json())
                  .then(
                    (response) => {
                      if (response.cards.length > 0) {
                        return response.cards
                      }
                      return []
                    },
                    (rejected) => {
                      console.error("Rejected: ", rejected)
                      return []
                    }
                  )
              })

              Promise.all(fetchPromises).then(
                (responses) => {
                  let cardsWithResponses = responses.filter(
                    (cardResps) => cardResps.length > 0
                  )
                  setCards(cardsWithResponses)
                  forceUpdate(true)
                  setLoader(false)
                },
                (rejected) => {
                  console.log("rejected: ", rejected)
                }
              )
            } else {
              console.log("no hooks were found")
              setLoader(false)
              setCards([])
            }
          }
        )
    }
  }

  const inputProps = {
    placeholder: "Skriv minst tre bokstaver for forslag...",
    value,
    required: true,
    onChange: (_e, { newValue }) => setValue(newValue),
  }

  const getSuggestionValue = (suggestion) => {
    setSelectedObservation(suggestion)
    // the line to connect pulseobservation:
    // getPulseobservation();
    //// getObservationCard(suggestion.conceptId);
    // callCDS(suggestion)
    return suggestion.pt.term + " (SCTID: " + suggestion.conceptId + ")"
  }

  const renderSuggestion = (suggestion) => (
    <div>
      {/** taking from hdir descriptions */}
      {suggestion.pt.term + " (SCTID: " + suggestion.conceptId + ")"}
    </div>
  )

  const filterSuggestions = async ({ value }) => {
    if (value.length < 3) return;
    try {
    const url = conceptQueryObservation + value;
    const res = await fetch(url);
    if (!res.ok) throw new Error(`Feil ved henting av data fra ${url}: ${res.status}`);
    const results = await res.json();
    setSuggestions(results.items)
  } catch (error) {
    handleError(error.message);
    console.error("En feil oppstod under filtrering av forslag:", error);
    // Her kan du velge å vise en feilmelding i UI eller håndtere feilen på en annen måte.
  }
};


  const params = useParams()
  const location = useLocation()

  const navigator = useNavigate()

  // the line to connect pulseobservation:
  // const getPulseobservation = async () => {
  //   fetch(fhirServer + `/Observation?patient=${params.patientId}`)
  //   .then(response => {
  //     if (response.ok) {
  //       return response.json();
  //     } else {
  //       throw new Error('Network response was not ok');
  //     }
  //   })
  //   .then(existingPatientObservation => {
  //     fetch(pulseobservationURL, {
  //       method: "POST",
  //       body: JSON.stringify({
  //         prefetch: {
  //           observation: existingPatientObservation,
  //         },
  //       }),
  //       headers: {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //       },
  //     })
  //     .then(res => res.json())
  //     .then(pulseobservation => {
  //       console.log("pulseobservation", pulseobservation);
  //       setPulseObservation([pulseobservation.cards]);
  //     });
  //   });
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const observationRequestData = {
      resourceType: "Observation",
      status: "final",
      code: {
        coding: [
          {
            code: selectedObservation.conceptId,
            system: "http://snomed.info/sct",
            display: selectedObservation.pt.term,
          },
        ],
      },
      subject: {
        reference: `Patient/${event.target.elements.patientId.value}`,
      },
      effectiveDateTime: new Date(),
      valueQuantity: {
        value: parseFloat(observationValue),
        system: "http://unitsofmeasure.org",
        unit: selectedObservation.unit || customUnit // Bruk den valgte enheten direkte
      },
    };
  
    await fetch(fhirServer + "/Observation", {
      method: "POST",
      body: JSON.stringify(observationRequestData),
      headers: headers,
    });
  
    setFormDetails(observationRequestData);
    // await getPulseobservation();
    setSubmissionTime(new Date());
  }
  



  const handleSuggestionSelected = (_, { suggestion }) => {
    console.log("Selected suggestion:", suggestion);
    const unitCode = findUnitForObservation(suggestion.conceptId);
    console.log("Found unit code:", unitCode);
    
    // Setter den valgte observasjonen med enhetsinformasjon
    setSelectedObservation({ ...suggestion, unit: unitCode });
  
    // Setter enhetstilstanden basert på om en matchende enhet ble funnet
    if (unitCode) {
      setUnits(unitCode); // Enhet funnet i JSON-dataene
      setCustomUnit(''); // Tømmer den brukerdefinerte enhetsteksten
    } else {
      setUnits(''); // Ingen enhet funnet, så sett units til tom streng
      // Ikke sett customUnit for å tillate brukeren å skrive inn en verdi
      // Her kan du også velge å sette en standardverdi eller instruksjon
      setCustomUnit(''); // Eller hva som helst du ønsker som standardverdi
    }
  };

const findUnitForObservation = (observationCode) => {
  console.log("Looking for unit with code:", observationCode);
  const unitEntry = unitsData.find(u => u.code === observationCode);
  console.log("Unit entry found:", unitEntry);
  return unitEntry ? unitEntry.unitcode : '';
};

// I useEffect, legg til en konsollogg for å bekrefte at enhetskode er funnet
useEffect(() => {
  console.log("selectedObservation changed:", selectedObservation);
  if (selectedObservation.conceptId) {
    const unitCode = findUnitForObservation(selectedObservation.conceptId);
    console.log("Setting units to:", unitCode);
    setUnits(unitCode); // Anta at setUnits er metoden for å oppdatere tilstanden for enheten
  }
}, [selectedObservation]);



  return (
    <Container>
      <Button className="home" onClick={() => navigator(`/`)}>
        <HomeIcon />
      </Button>
      <br />
      <Button
        className="back submit-button"
        onClick={
          () => navigator(`${location.state.path}/${location.state.id ?? ""}`)
          //?! navigator(`patient/${location.state.id ?? ""}`)
        }
      >
        <ArrowBackIcon />
      </Button>

      <Row>
        <Col>
          <Form onSubmit={handleSubmit}>
            <h1>Observasjon</h1>
            <Container className="c5" xs={40}>
              <Row xs={40}>
                <Col>
                  <FloatingLabel className="my-3">
                    <Form.Group as={Col} controlId="formGridCity">
                      <Form.Label>Pasient-id FHIR</Form.Label>
                      <Form.Control
                        required
                        name="patientId"
                        placeholder="Patient Id"
                        value={params.patientId}
                        onChange={() => {}}
                        readOnly
                        disabled
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </FloatingLabel>
                </Col>

                <Col>
                  <FloatingLabel className="my-3">
                    <Form.Group as={Col} controlId="formGridCity">
                      <Form.Label>Observasjon</Form.Label>
                      <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={filterSuggestions}
                        onSuggestionsClearRequested={() => setSuggestions([])}
                        onSuggestionSelected={handleSuggestionSelected}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                        value={value}
                        required
                     />
                    </Form.Group>
                  </FloatingLabel>
                </Col>

                <Col>
                  <FloatingLabel className="my-3">
                    <Form.Group as={Col}>
                      <Form.Label>Verdi</Form.Label>
                      <Form.Control
                        name="value"
                        required
                        onChange={(evt) => {
                          setObservationValue(evt.target.value)
                          /*   setTimeout(() => {
                            callCDS(selectedObservation, evt.target.value)
                            // the line to connect pulseobservation:
                            // getPulseobservation();
                          }, 1500)*/
                        }}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </FloatingLabel>
                </Col>

                <Col>
                  <FloatingLabel className="my-3">
                    <Form.Group as={Col}>
                    <Form.Label>Enhet</Form.Label>
                    {
                    units ? (
                      <Form.Control
                        style={{ height: "40px" }}
                        name="unit"
                        value={units}
                        onChange={(e) => setUnits(e.target.value)}
                        required
                        />
                    ) : (
                      <Form.Control
                        type="text"
                        placeholder="Skriv inn enhet"
                        value={customUnit}
                        onChange={(e) => setCustomUnit(e.target.value)}
                      />
                    )
                  }
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>

                    </Form.Group>
                  </FloatingLabel>
                </Col>
                <Col className="hidden">
                  <FloatingLabel className="my-3">
                    <Form.Group as={Col}>
                      <Form.Label>Tull</Form.Label>
                      <Form.Select
                        style={{ height: "40px" }}
                        name="hack"
                        onChange={(evt) => {
                          //   setUnit(evt.target.value);
                          setTimeout(() => {
                            // :(
                            callCDS(selectedObservation, evt.target.value)
                          }, 1500)
                        }}
                      >
                      </Form.Select>
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </FloatingLabel>
                </Col>
              </Row>
            </Container>

            <Button type="submit" className="submit-button">
              Registrer
            </Button>
            {loader && <Loader />}

            <Container className="c5" xs={40}>
              {!!submissionTime && (
                <Form.Label>
                  {" "}
                  <h5>Registrert:</h5> {submissionTime.toLocaleString("nb-NB")}
                </Form.Label>
              )}
            </Container>
          </Form>
        </Col>
      </Row>

      {/* the line to connect pulseobservation: */}
      {/* {!!pulseobservation &&
        <Col>
          <CDSCardComponent dataCards={pulseobservation} showMoreCard={showMoreCard} setShowMoreCard={setShowMoreCard} />
        </Col>
      } */}

      <Row>
        {!!formDetails && (
          <Container>
            <FloatingLabel className="col4">
              <Button
                className="showFhir"
                variant="outline-info"
                onClick={() => setShowJson(!showJson)}
              >
                {showJson ? "Skjul FHIR-data" : "Vis FHIR-data"}
              </Button>
              {showJson && (
                // <div>
                <Container>
                  <FloatingLabel controlId="floatingTextarea2">
                    {/* <br /> */}
                    <h2>FHIR-data</h2>
                    <Form.Control
                      value={JSON.stringify(formDetails, null, 2)}
                      as="textarea"
                      placeholder="For example : "
                      style={{ height: "430px" }}
                      onChange={() => {}}
                    />
                  </FloatingLabel>
                </Container>
              )}
            </FloatingLabel>
          </Container>
        )}
      </Row>

      <Row>
        <Col>
          <CDSCardComponent
            currentMedication={selectedObservation}
            dataCards={dataCards}
            showMoreCard={showMoreCard}
            setShowMoreCard={setShowMoreCard}
            filterCards={false}
          />
        </Col>
      </Row>

      <div>
      {/* Din komponent JSX her */}
      {error && <ErrorModal errorMessage={error} onClose={handleCloseErrorModal} />}
      {/* Andre komponenter og JSX... */}
    </div>

    </Container>
  )
}

export default Observation
