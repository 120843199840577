import { Spinner } from "reactstrap";
import "./Loader.css";

const Loader = () => {
  return (
    <div className="backdrop-container">
      <div style={{ zIndex: 100 }}>
        <Spinner color="success" />
      </div>
      <div className="backdrop"></div>
    </div>
  );
};
export default Loader;