export const createPatientSummaryPromptMed = (patient, conditions, medications, observations, currentMedication, /*message,*/ reasonForSwitching) => {
  // Sjekk om vi har nødvendig informasjon
  if (!patient || !conditions || !medications || !observations) {
    return "Pasientinformasjon er utilstrekkelig for å lage en oppsummering.";
  }

  // Samle pasientens basisinformasjon
  const patientInfo = `
    Fødselsdato: ${patient.birthDate || ""}
    Kjønn: ${patient.gender || ""}
  `;

  // Samle pasientens diagnoser
  const conditionList = conditions.map(condition => {
    return `- ${condition.code?.coding[0]?.display || "Ukjent diagnose"} `;
  }).join("\n");

  // Samle pasientens medikamenter
  const medicationList = medications.map(medication => {
    const indication = medication.reasonReference && medication.reasonReference.length > 0
      ? (medication.reasonReference[0]?.display || "Ukjent indikasjon")
      : "Ukjent indikasjon";
    return `- ${medication.medicationCodeableConcept?.coding[0]?.display || "Ukjent medikament"} (Dose: ${medication.dosageInstruction[0]?.text || "Ukjent dose"}, Indikasjon: ${indication})`;
  }).join("\n");

  // Samle pasientens observasjoner med datoer
  const observationList = observations.map(observation => {
      const dateTime = new Date(observation.effectiveDateTime).toLocaleDateString("nb-NO");
      const value = observation.valueQuantity ? `Verdi: ${observation.valueQuantity.value} ${observation.valueQuantity.unit}` : "Ingen verdi";
      return `- ${observation.code?.coding[0]?.display || "Ukjent observasjon"} (Dato: ${dateTime}) (${value})`;
    }).join("\n");

  // Legg til aktuell medisin informasjon
  const currentMedicationIndication = currentMedication.reasonReference && currentMedication.reasonReference.length > 0
    ? (currentMedication.reasonReference[0]?.display || "Ukjent indikasjon")
    : "Ukjent indikasjon";
  const currentMedicationInfo = currentMedication ? `
      - ${currentMedication.medicationCodeableConcept?.coding[0]?.display || "Ukjent medikament"} (Dose: ${currentMedication.dosageInstruction[0]?.text || "Ukjent dose"}, Indikasjon: ${currentMedicationIndication})
  ` : "";

  /* // Legg til brukermeldingen
  const userMessage = message ? `
    Begrunnelse for bytte:
    ${message}
  ` : ""; */

  // Lag prompten
  const prompt = `
  Det gjelder dette legemiddelet: ${currentMedicationInfo}.

  Her er relevant pasientinformasjon som jeg ønsker at du tar med i beregningene til dette forslaget. Ta hensyn til ting som alder, diagnoser, kontraindikasjoner med andre medisiner og observasjoner.
  
  Pasientinformasjon:
    ${patientInfo}
    
    Diagnoser:
    ${conditionList}
    
    Medisinering:
    ${medicationList}
    
    Observasjoner:
    ${observationList}
  `;

  return prompt;
}
