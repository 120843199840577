import React from "react";
import { Card } from "react-bootstrap";
import "./Cds.css"; // Bruker samme CSS som CdsResponseComponent
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes , faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import parse from 'html-react-parser';

export const CurrentCdsComponent = ({ cdsCardData, patientAge, onClose }) => {
  // Definerer en liste over medikamentkategorier som kun skal vises for pasienter over 65
  const ageSensitiveSummaries = [
    "TCA (trisykliske antidepressiver)",
    "Antipsykotika (nevroleptika)",
    "Antihistaminer – 1. generasjon",
    "Antikolinerge midler mot overaktiv blære",
    "Sulfonylurea"
  ];

  // Sjekk om kortets summary inneholder noen av de alder-sensitive overskriftene
  const isAgeSensitive = ageSensitiveSummaries.some(summary => cdsCardData.summary.includes(summary));
  const shouldDisplayCard = patientAge > 65 ? true : !isAgeSensitive;

  if (!shouldDisplayCard) {
    return null; // Ikke vis kortet hvis det ikke skal vises basert på alder
  }


  // Tittel basert på om kortet er alder-sensitivt
  const cardTitle = isAgeSensitive
  ? {
      text: "Legemiddelet bør unngås da pasienten er over 65 år",
      iconClass: "text-warning1a"
    }
  : {
      text: "Legemiddel med risiko for alvorlige bivirkninger",
      iconClass: "text-warning2a"
    };



  return (

        <Card className="cdsModal">
          <Card.Header>
          <FontAwesomeIcon icon={faExclamationTriangle} className={cardTitle.iconClass} />{' '}
            <span>{cardTitle.text}</span>
            <button onClick={onClose} className="close-button">
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </Card.Header>
          <Card.Body className="cdsResponseBody">
            <h4 className="cdsResponseMainTitle">{parse(cdsCardData.summary)}</h4>
            {cdsCardData.detail && <div className="cdsResponseDetail">{parse(cdsCardData.detail)}</div>}
            <div className="cdsResponseFooter">
              <span>Kilde:</span> 
              <a href="https://www.helsebiblioteket.no/innhold/artikler/legemidler/legemiddelaktuelt/sjekkliste-for-for-legemiddelsamstemming-og-legemiddelgjennomgang#:~:text=Noen%20legemidler%20med%20risiko%20for%20alvorlige%20bivirkninger%3A" 
                 target="_blank" 
                 rel="noopener noreferrer">
                 Sjekkliste for legemiddelsamstemming og legemiddelgjennomgang
              </a>
              <div className="cdsResponseProvider">
                <span>Tjenesten leveres av</span> 
                <a href={cdsCardData.source.url} 
                   target="_blank" 
                   rel="noopener noreferrer"
                   className="cdsResponseLink">
                  {cdsCardData.source.label}
                </a>
              </div>  
              </div>
          </Card.Body>     
        </Card>

  );
};

export default CurrentCdsComponent;
