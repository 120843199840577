import React, { useState, useRef } from "react";
import Autosuggest from "react-autosuggest";
import "bootstrap/dist/css/bootstrap.min.css";
import "./AutoComplete.css";
import { fhirServer, GETparameters } from "../config.js";

function GetPatients(props) {
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(undefined);
  const query = useRef("");

  const getSuggestionValue = (suggestion) => {
    // console.log("suggestion in getSuggestionValue", suggestion);
    console.log("selectedPatient", selectedPatient);
    setSelectedPatient(suggestion);
    props.suggestCallback(suggestion);

    return (
      suggestion.resource.name[0].given +
      " " +
      suggestion.resource.name[0].family
    );
  };

  const renderSuggestion = (suggestion) => (
    <div>
      {suggestion.resource.name[0].given} {suggestion.resource.name[0].family}{" "}
      {"(id: "}
      {suggestion.resource.id} {")"}
    </div>
  );

  const onSuggestionsFetchRequested = ({ value }) => {
    if (value.length < 3) return;
    query.current = value;
    setTimeout(() => fetchSuggestions(value), 350);
  };

  const fetchSuggestions = (value) => {
    // console.log("on fetch requested!", value);
    const baseUrl = fhirServer + "/" + props.keyWord;
    let fetchUrl;
    // if (name.length>0) {
    let nameArray = value.split(/\s+/);
    fetchUrl = baseUrl + "?name=" + nameArray.join("&name=");
    // } else {
    // fetchUrl = baseUrl;
    // }
    fetch(fetchUrl, GETparameters)
      .then((response) => response.json())
      .then((data) => {
        if (data.total === 0) {
          console.log("data found 0");
          props.noResultFoundCallback();
        }
        // console.log("data.entry", data.entry);
        if (query.current === value) {
          setSuggestions(data.entry ?? []);
        }
      });
  };

  const inputProps = {
    placeholder: "For- eller etternavn",
    value,
    required: true,
    onChange: (_e, { newValue }) => setValue(newValue),
  };

  return (
    <div>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={() => setSuggestions([])}
        onSuggestionSelected={(_, { suggestion }) =>
          console.log(
            "Selected: " +
              suggestion.resource.name[0].family +
              ": " +
              suggestion.resource.id
          )
        }
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        value={value}
        required
      />
    </div>
  );
}

export default GetPatients;
