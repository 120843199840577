import React from "react"
import { Card, Container } from "react-bootstrap"
import "./ClinicalUseDefinitionComponent.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

export const ClinicalUseDefinitionComponent = ({
  clinicalUseDefinitionResources,
  closeCard,
}) => (
  <Container className="clinicalUseDefinitions">
    {clinicalUseDefinitionResources?.map((clinicalUseDefinition, idx) => {
      let resource = clinicalUseDefinition.resource
      let category = null
      let subCategory = null
      let title = null
      resource.category?.forEach((cat) => {
        cat.coding?.forEach((coding) => {
          if (
            coding.system ===
            "https://felleskatalogen.no/fhir/r4/cds/nyre-advarsel/varsel-kategori"
          ) {
            category = coding.display
          } else if (
            coding.system ===
            "https://felleskatalogen.no/fhir/r4/cds/nyre-advarsel/varsel-underkategori"
          ) {
            subCategory = coding.display
          }
        })
      })
      resource.warning?._description?.extension.forEach((ext) => {
        if (
          ext.url ===
          "https://felleskatalogen.no/fhir/r4/cds/nyre-advarsel/advarsel-tittel"
        ) {
          title = ext.valueString
        }
      })
      return (
        <Card className="clinicalUseDefinitions mb-3" key={`cud-${idx}`}>
          <Card.Header className="d-flex justify-content-between align-items-center">
            <strong>
              {category} - {subCategory}
            </strong>
            <button onClick={closeCard} className="close-button">
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </Card.Header>
          <Card.Body>
            <Card.Title>{title}</Card.Title>
            <Card.Text
              dangerouslySetInnerHTML={{ __html: resource.warning.description }}
            />
            <small>Kilde: Felleskatalogen AS</small>
          </Card.Body>
        </Card>
      )
    })}
  </Container>
)
