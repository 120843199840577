import React from 'react';

function ErrorModal({ errorMessage, errorNumber, onClose }) {
  return (
    <div style={{
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      padding: '20px',
      zIndex: 1000,
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      border: '1px solid #ccc',
      borderRadius: '5px',
      minWidth: '300px',
    }}>
      <h2>Får ikke kontakt med Snowstorm</h2>
      {errorNumber && <p>Feilnummer: {errorNumber}</p>}
      <p>{errorMessage}</p>
      <button onClick={onClose} style={{
        backgroundColor: 'grey',
        color: 'white',
        border: 'none',
        padding: '10px 20px',
        borderRadius: '5px',
        cursor: 'pointer',
        marginTop: '10px',
      }}>
        Lukk
      </button>
    </div>
  );
}

export default ErrorModal;