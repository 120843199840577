import "./App.css";
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Patient from "./components/Patient";
import CreatePateint from "./components/CreatePateint";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Observation from "./components/Observation";
import Condition from "./components/Condition";
import Home from "./Home";
import { createBrowserHistory } from "history";
import MedicationRequest from "./components/MedicationRequest";
import LoginComponent from "./components/Login/LoginComponent";
import Root from "./components/Root";

export default function App() {
  const history = createBrowserHistory();

  // const protectedPaths = [
  //   <Route key="1" path="/home/patient/:patientId" element={<Patient />} />,
  //   <Route key="2" path="/home/patient/" exact element={<Patient />} />,
  //   <Route key="3" path="/home/createPatient/" exact element={<CreatePateint />} />,
  //   <Route key="4" path="/home/observation/:patientId" exact element={<Observation />} />,
  //   <Route key="5" path="/home/condition/:patientId" exact element={<Condition />} />,
  //   <Route key="6" path="/home/medication/:patientId" exact element={<MedicationRequest />} />
  // ];

  return (
    <div className="App">
      <BrowserRouter history={history}>
        <Routes>
          <Route path="/" element={<Root />}>
            {/*<Route path="/" exact element={<Navigate to="/home"/>}/>*/}
            <Route path="/" element={<Home />} />
            <Route path="patient" element={<Patient />} />
            <Route path="patient/:patientId" element={<Patient />} />
            <Route path="createpatient" element={<CreatePateint />} />
            {/* <Route path="createpatient/:patientId" element={<CreatePateint />} /> */}
            <Route path="observation/:patientId" exact element={<Observation />} />
            <Route path="condition/:patientId" exact element={<Condition />} />
            <Route path="medication/:patientId" exact element={<MedicationRequest />} />
          </Route>
          <Route path="login" element={<LoginComponent />}></Route>
          {/* <Route path="/home" element={<Home />}></Route> */}
          {/* <Route path="/patient/:patientId" element={<Patient />}></Route>
          <Route path="/patient/" element={<Patient />} />
          <Route path="/createPatient/" element={<CreatePateint />} />
          <Route path="/observation/:patientId" element={<Observation />} />
          <Route path="/condition/:patientId" element={<Condition />} />
          <Route
            path="/medication/:patientId"
            element={<MedicationRequest />}
          /> */}
          {/* <Route path="*" element={<p>There's nothing here: 404!</p>} /> */}
          <Route path="*" element={<Navigate to="/"/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
