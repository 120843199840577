import React from "react";
import { Card } from "react-bootstrap";
import "./Cds.css"; // Bruker samme CSS som CdsResponseComponent
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import parse from 'html-react-parser';
import kidneySymbolImage from './assets/nyre.png';

export const CurrentCdsComponentNyre = ({ cdsCardDataNyre, onCloseNyre }) => {

  // Define the main header for the card
  const category = cdsCardDataNyre.extension["no.felleskatalogen.cds.nedsatt-nyre.kategori"];
  const subcategory = cdsCardDataNyre.extension["no.felleskatalogen.cds.nedsatt-nyre.underkategori"];
  const cardTitle = {
      text: `${category} - ${subcategory}`, // This keeps "Forsiktighet - Dosejustering"
      iconClass: "text-warning1a"
  };

  // Extract only the `title-criteria` text and ignore `title-medication`
  const parser = new DOMParser();
  const doc = parser.parseFromString(cdsCardDataNyre.summary, 'text/html');
  const titleCriteria = doc.querySelector('.title-criteria')?.textContent || "Legemiddel";

  return (
    <Card className="cdsModal">
      <Card.Header>
        <img src={kidneySymbolImage} alt="Nyresymbol" className="text-kidney4" />{' '}
        <span>{cardTitle.text}</span>
        <button onClick={onCloseNyre} className="close-button">
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </Card.Header>
      <Card.Body className="cdsResponseBody">
        {/* Display only the title-criteria text, which is "Aciklovir - oral - GFR <25" */}
        <h4 className="cdsResponseMainTitle">{titleCriteria}</h4>
        
        {/* Display the details */}
        {cdsCardDataNyre.detail && <div className="cdsResponseDetail">{parse(cdsCardDataNyre.detail)}</div>}
        
        <div className="cdsResponseFooter">
          <div className="cdsResponseProvider">
            <span>Tjenesten leveres av</span> 
            <a href={cdsCardDataNyre.source.url} 
               target="_blank" 
               rel="noopener noreferrer"
               className="cdsResponseLink">
              {cdsCardDataNyre.source.label}
            </a>
          </div>  
        </div>
      </Card.Body>     
    </Card>
  );
};

export default CurrentCdsComponentNyre;
