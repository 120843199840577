import React, { useState } from "react"
import "./styles.css"
import "bootstrap/dist/css/bootstrap.min.css"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import FloatingLabel from "react-bootstrap/FloatingLabel"
import "react-phone-input-2/lib/style.css"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import { useNavigate, useParams } from "react-router-dom"
import "./AutoComplete.css"
import { fhirServer, headers } from "../config.js"
const relationships = [
  {
    code: "CP",
    system: "http://terminology.hl7.org/CodeSystem/v2-0131",
    display: "Kontaktperson",
  },

  {
    code: "PR",
    system: "http://terminology.hl7.org/CodeSystem/v2-0131",
    display: "Henviser",
  },
  {
    code: "E",
    system: "http://terminology.hl7.org/CodeSystem/v2-0131",
    display: "Arbeidsgiver",
  },
]

function CreatePateint() {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [EmergencyfirstName, setEmergencyFirstName] = useState("")
  const [EmergencylastName, setEmergencyLastName] = useState("")
  const [dob, setDob] = useState("")
  const [gender, setGender] = useState("")
  const [line, setline] = useState("")
  const [city, setCity] = useState("")
  const [postalCode, setPostalCode] = useState("")
  const [country, setcountry] = useState("")
  const [formDetails, setFormDetails] = useState("")
  const [phoneNo, setPhoneNo] = useState("")
  const [EmergencyphoneNo, setEmergencyphoneNo] = useState("")

  const [email, setEmail] = useState("")
  const [showJson, setShowJson] = useState(false)
  const [newPatient, setNewPatient] = useState(undefined)
  const params = useParams()

  const navigator = useNavigate()

  const handleSubmit = async (event) => {
    console.log("handleSubmit ran")
    event.preventDefault() // 👈️ prevent page refresh

    // 👇️ access input values here
    console.log("firstName 👉️", firstName)
    console.log("lastName 👉️", lastName)
    const relationship = relationships.find(
      (type) => type.code === event.target.elements.relationship.value
    )
    console.log("relationshipssssssss", relationships)
    console.log("rela", relationship)
    const data = {
      resourceType: "Patient",
      name: [{ family: lastName, given: [firstName] }],
      gender: gender,
      birthDate: dob,
      address: [
        {
          line: [line],
          city: city,
          postalCode: postalCode,
          country: country,
        },
      ],
      telecom: [
        {
          system: "phone",
          value: phoneNo,
        },
        {
          system: "email",
          value: email,
        },
      ],
      contact: [
        {
          name: [
            {
              family: EmergencylastName,
              given: [EmergencyfirstName],
            },
          ],
          telecom: [
            {
              system: "phone",
              value: EmergencyphoneNo,
            },
          ],
          relationship: [
            {
              coding: [relationship],
            },
          ],
        },
      ],
    }
    const res = await fetch(fhirServer + "/Patient", {
      method: "POST",
      body: JSON.stringify(data),

      headers: headers,
    })
    const newPatient = await res.json()

    setNewPatient(newPatient)

    setFormDetails(data)
  }

  return (
    <Container className="wrapper">
      <Button className="back submit-button" onClick={() => navigator(`/`)}>
        Tilbake
      </Button>
      <h1>Ny pasient</h1>
      {!params.patientId && (
        <Form onSubmit={handleSubmit}>
          <Container className="c1" xs={40}>
            <h2>Pasientinformasjon</h2>
            <Row xs={40}>
              <Col>
                <FloatingLabel className="my-3">
                  <Form.Group as={Col}>
                    <Form.Label>Fornavn</Form.Label>
                    <Form.Control
                      required
                      id="first_name"
                      name="first_name"
                      type="text"
                      placeholder="Fornavn"
                      onChange={(event) => setFirstName(event.target.value)}
                      value={firstName}
                    />
                  </Form.Group>
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel className="my-3">
                  <Form.Group as={Col}>
                    <Form.Label>Etternavn</Form.Label>
                    <Form.Control
                      required
                      id="last_name"
                      name="last_name"
                      type="text"
                      placeholder="Etternavn"
                      value={lastName}
                      onChange={(event) => setLastName(event.target.value)}
                    />
                  </Form.Group>
                </FloatingLabel>
              </Col>

              <Col className="text-left">
                {" "}
                <FloatingLabel className="my-3">
                  <Form.Group as={Col}>
                    <Form.Label>Kjønn</Form.Label>

                    <Form.Select
                      as={Col}
                      onChange={(event) => setGender(event.target.value)}
                      value={gender}
                    >
                      <option value=" ">Velg kjønn</option>
                      <option value="female">Kvinne</option>
                      <option value="male">Mann</option>
                      <option value="other">Annet</option>
                      <option value="unknown">Ukjent</option>
                    </Form.Select>
                  </Form.Group>
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel className="my-3">
                  <Form.Group as={Col}>
                    <Form.Label>Fødselsdato</Form.Label>

                    <Form.Control
                      type="date"
                      onChange={(event) => setDob(event.target.value)}
                      value={dob}
                    ></Form.Control>
                  </Form.Group>
                </FloatingLabel>
              </Col>
            </Row>
          </Container>
          <Container className="c2" xs={40}>
            {" "}
            <h2>Adresse</h2>
            <Form.Group className="my-3" controlId="formGridAddress1">
              <Form.Label>Gate/Vei</Form.Label>
              <Form.Control
                placeholder="Gateadresse"
                onChange={(event) => setline(event.target.value)}
                value={line}
              />
            </Form.Group>
            <Row className="my-3">
              <Form.Group as={Col}>
                <Form.Label>By</Form.Label>
                <Form.Control
                  onChange={(event) => setCity(event.target.value)}
                  value={city}
                  placeholder="Oslo"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>Land</Form.Label>
                <Form.Control
                  onChange={(event) => setcountry(event.target.value)}
                  value={country}
                  placeholder="Norge"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridZip">
                <Form.Label>Postnummer</Form.Label>
                <Form.Control
                  onChange={(event) => setPostalCode(event.target.value)}
                  value={postalCode}
                  placeholder="0123"
                />
              </Form.Group>
            </Row>
          </Container>
          <Container className="c2" xs={40}>
            {" "}
            <h2>Kontakt</h2>
            <Row xs={40}>
              <Col>
                <FloatingLabel className="my-3">
                  <Form.Group as={Col}>
                    <Form.Label>E-postadresse</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="navn@eksempel.no"
                      onChange={(event) => setEmail(event.target.value)}
                      value={email}
                    />
                  </Form.Group>
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel className="my-3">
                  <Form.Group as={Col}>
                    <Form.Label>Telefon</Form.Label>
                    <Form.Control
                      required
                      type="phone"
                      country={"no"}
                      placeholder="+471234567"
                      onChange={(event) => setPhoneNo(event.target.value)}
                      value={phoneNo}
                    />
                  </Form.Group>
                </FloatingLabel>
              </Col>
            </Row>
          </Container>

          <Container className="c5" xs={40}>
            {" "}
            <h2>Pårørende</h2>
            <Row xs={40}>
              <Col>
                <FloatingLabel className="my-3">
                  <Form.Group as={Col}>
                    <Form.Label>Fornavn</Form.Label>
                    <Form.Control
                      placeholder="Fornavn"
                      type="text"
                      onChange={(event) =>
                        setEmergencyFirstName(event.target.value)
                      }
                      value={EmergencyfirstName}
                    />

                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel className="my-3">
                  <Form.Group as={Col}>
                    <Form.Label>Etternavn</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Etternavn"
                      value={EmergencylastName}
                      onChange={(event) =>
                        setEmergencyLastName(event.target.value)
                      }
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel className="my-3">
                  <Form.Group as={Col}>
                    <Form.Label>Telefon</Form.Label>
                    <Form.Control
                      type="phone"
                      country={"no"}
                      placeholder="+471234567"
                      onChange={(event) =>
                        setEmergencyphoneNo(event.target.value)
                      }
                      value={EmergencyphoneNo}
                    />
                  </Form.Group>
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel className="my-3">
                  <Form.Group as={Col} controlId="formGridCity">
                    <Form.Label>Forhold</Form.Label>
                    <Form.Select
                      aria-label="Floating label select example"
                      style={{ height: "40px" }}
                      name="relationship"
                      onChange={() => {}}
                      required
                    >
                      <option value="">Velg </option>
                      {relationships.map((type) => {
                        return (
                          <option key={type.code} value={type.code}>
                            {type.display}
                          </option>
                        )
                      })}
                    </Form.Select>
                  </Form.Group>
                </FloatingLabel>
              </Col>
            </Row>
          </Container>

          {!params.patientId && (
            <Button type="submit" className="submit-button">
              Opprett pasient
            </Button>
          )}
        </Form>
      )}
      {!!formDetails && (
        <Container>
          <FloatingLabel className="col4">
            <Button
              className="showFhir"
              variant="outline-info"
              onClick={() => setShowJson(!showJson)}
            >
              {showJson ? "Skjul FHIR-data" : "Vis FHIR-data"}
            </Button>
            {showJson && (
              <Container>
                <FloatingLabel controlId="floatingTextarea2">
                  <h2>FHIR-data</h2>
                  <Form.Control
                    value={JSON.stringify(formDetails, null, 2)}
                    as="textarea"
                    placeholder="For example : "
                    style={{ height: "430px" }}
                    onChange={() => {}}
                  />
                </FloatingLabel>
              </Container>
            )}
          </FloatingLabel>
        </Container>
      )}

      {!!newPatient?.id && (
        <Container style={{ marginTop: 20 }}>
          <Row>
            <Col>
              <Button
                className="add-item submit-button"
                onClick={() =>
                  navigator(`/observation/${newPatient?.id}`, {
                    state: { path: "/patient", id: newPatient?.id },
                  })
                }
              >
                Legg til ny observasjon
              </Button>
            </Col>
            <Col>
              <Button
                className="add-item submit-button"
                onClick={() =>
                  navigator(`/Condition/${newPatient.id}`, {
                    state: { path: "/CreatePateint" },
                  })
                }
              >
                Legg til ny problemstilling
              </Button>
            </Col>
            <Col>
              <Button
                className="add-item submit-button"
                onClick={() =>
                  navigator(`/medication/${newPatient.id}`, {
                    state: { path: "/CreatePateint" },
                  })
                }
              >
                Legg til ny medisinering
              </Button>
            </Col>
          </Row>
        </Container>
      )}
    </Container>
  )
}

export default CreatePateint
