import { apiSearchScopeAction } from "../authConfig";
import {useMsal} from "@azure/msal-react";

export function useAuthToken() {
  const { instance, accounts } = useMsal();

  return async () => {
    const accessTokenRequest = {
      scopes: [apiSearchScopeAction],
      account: accounts[0],
    };
    try {
      const accessTokenResponse = await instance.acquireTokenSilent(
        accessTokenRequest
      );
      return accessTokenResponse.accessToken;
    } catch (error) {
      console.log(
        "Silent token acquisition fails. Acquiring token using popup"
      );
      try {
        const accessTokenResponse = await instance.acquireTokenPopup(
          accessTokenRequest
        );
        return accessTokenResponse.accessToken;
      } catch (err) {
        console.error(err);
        return null;
      }
    }
  };
}

