  // promptUtils.js

export const createPatientSummaryPrompt = (patient, conditions, medications, observations) => {
    // Sjekk om vi har nødvendig informasjon
    if (!patient || !conditions || !medications || !observations) {
      return "Pasientinformasjon er utilstrekkelig for å lage en oppsummering.";
    }
  
    // Samle pasientens basisinformasjon
    const patientInfo = `
      Fødselsdato: ${patient.birthDate || ""}
      Kjønn: ${patient.gender || ""}
    `;
  
    // Samle pasientens diagnoser
    const conditionList = conditions.map(condition => {
      return `- ${condition.code?.coding[0]?.display || "Ukjent diagnose"} `;
    }).join("\n");
  
    // Samle pasientens medikamenter
    const medicationList = medications.map(medication => {
        const indication = medication.reasonReference && medication.reasonReference.length > 0
          ? (medication.reasonReference[0]?.display || "Ukjent indikasjon")
          : "Ukjent indikasjon";
        return `- ${medication.medicationCodeableConcept?.coding[0]?.display || "Ukjent medikament"} (Dose: ${medication.dosageInstruction[0]?.text || "Ukjent dose"}, Indikasjon: ${indication})`;
      }).join("\n");
  
    // Samle pasientens observasjoner med datoer
    const observationList = observations.map(observation => {
        const dateTime = new Date(observation.effectiveDateTime).toLocaleDateString("nb-NO");
        const value = observation.valueQuantity ? `Verdi: ${observation.valueQuantity.value} ${observation.valueQuantity.unit}` : "Ingen verdi";
        return `- ${observation.code?.coding[0]?.display || "Ukjent observasjon"} (Dato: ${dateTime}) (${value})`;
      }).join("\n");
  
    // Lag prompten
    const prompt = `
      Pasientinformasjon:
      ${patientInfo}
      
      Diagnoser:
      ${conditionList}
      
      Medisinering:
      ${medicationList}
      
      Observasjoner:
      ${observationList}
    `;
  
    return prompt;
  }
  

/*


  // promptUtils.js

export const createPatientSummaryPrompt = (patient, conditions, medications) => {
    // Sjekk om vi har nødvendig informasjon
    if (!patient || !conditions || !medications) {
      return "Pasientinformasjon er utilstrekkelig for å lage en oppsummering.";
    }
  
    // Samle pasientens basisinformasjon
    const patientInfo = `
      Pasient ID: ${patient.id}
      Navn: ${patient.name[0]?.given[0] || ""} ${patient.name[0]?.family || ""}
      Fødselsdato: ${patient.birthDate || ""}
      Kjønn: ${patient.gender || ""}
      Adresse: ${patient.address[0]?.line[0] || ""}, ${patient.address[0]?.city || ""}, ${patient.address[0]?.postalCode || ""}, ${patient.address[0]?.country || ""}
      Telefon: ${patient.telecom.find(item => item.system === "phone")?.value || ""}
      E-post: ${patient.telecom.find(item => item.system === "email")?.value || ""}
    `;
  
    // Samle pasientens diagnoser
    const conditionList = conditions.map(condition => {
      return `- ${condition.code?.coding[0]?.display || "Ukjent diagnose"} (SCTID: ${condition.code?.coding[0]?.code || "Ukjent"})`;
    }).join("\n");
  
    // Samle pasientens medikamenter
    const medicationList = medications.map(medication => {
      return `- ${medication.medicationCodeableConcept?.coding[0]?.display || "Ukjent medikament"} (Dose: ${medication.dosageInstruction[0]?.text || "Ukjent dose"})`;
    }).join("\n");
  
    // Lag prompten
    const prompt = `
      Pasientinformasjon:
      ${patientInfo}
      
      Diagnoser:
      ${conditionList}
      
      Medisinering:
      ${medicationList}
    `;
  
    return prompt;
  }
  
*/