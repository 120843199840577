import React from "react"
import { Row, Col, Container } from "react-bootstrap"

const Footer = () => {
  return (
    <Container className="footer">
      <Row>
        <Col>
          <img
            src="assets/semanticLabLogo.png"
            alt="Semantic logo"
            width="140px"
            className="floatLeft"
          />
          
        </Col>
        <Col>
          <img
            className="floatRight"
            src="assets/fkLogo.png"
            alt="Felleskatalogen logo"
            width="200px"
          />
        </Col>
      </Row>
    </Container>
  )
}

export default Footer
