import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import {Outlet} from "react-router-dom";
import {useMsal} from '@azure/msal-react'
import LoginComponent from "./Login/LoginComponent";

const Root = () => {
  // const navigator = useNavigate();

  // const [state, setState] = useState({loaded: false, success: false});
  const { accounts } = useMsal()

  // // Always check if logged in
  // useEffect(() => {
  //   fetch(authServer + "/isLoggedIn", {
  //     credentials: "include"
  //   })
  //   .then((data) => data.json())
  //   .then((resp) => {
  //     console.log(resp);
  //     if (resp.authorized) setState({loaded: true, success: true});
  //     else setState({loaded: true, success: false});
  //   });
  // }, []);

  // if (inProgress === "none") {
  //   if (accounts.length === 0) {
  //     return <LoginComponent />
  //   } else {
  //     return <Outlet></Outlet>
  //   }
  // }
  // return <></>; // TODO Loading
  return <div>
    <LoginComponent />
    {accounts.length >= 1 && <Outlet />}
  </div>
};

export default Root;
