import React from "react"
import CircleIcon from "@mui/icons-material/Circle"
import { Card, Button, Container, Row, FloatingLabel } from "react-bootstrap"
import "./AutoComplete.css"

export const CDSCardComponent = ({
  currentMedication,
  dataCards,
  showMoreCard,
  setShowMoreCard,
  filterCards,
}) => (
  <Row className="cdsCard">
    {dataCards?.map((cdsCard, idx) => {
      return (
        <Container className="submit-button" key={`observation-${idx}`}>
          <FloatingLabel>
            <Card className="pulsecard">
              {cdsCard.map((card, cdsIdx) => {
                const medicationsList =
                  card?.extension?.["no.felleskatalogen.cds"]?.[
                    "legemiddel-liste"
                  ]

                return (
                  // if using a new cqf-server and condition with extension:
                  medicationsList?.length > 0 ? (
                    medicationsList.map((item, index) => {
                      const condition =
                        item.code === currentMedication.conceptId ||
                        !filterCards

                      if (condition) {
                        return (
                          <Card.Body key={`c-${cdsIdx}-${index}`}>
                            {card.source?.icon && (
                              <img
                                src={card?.source?.icon}
                                height={100}
                                alt={card?.source?.title ?? "source icon"}
                              />
                            )}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <h3>{card.summary}</h3>
                              <CircleIcon
                                style={{
                                  color:
                                    card.indicator === "critical"
                                      ? "rgb(204, 0, 0)"
                                      : card.indicator === "warning"
                                      ? "rgb(204 164 0)"
                                      : "black",
                                  fontSize: "2em",
                                }}
                              />
                            </div>
                            <div>
                              <b>Kilde: </b>
                              {card.source?.url ? (
                                <a
                                  href={card.source?.url}
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  {card.source?.label}
                                </a>
                              ) : (
                                "Felleskatalogen"
                              )}
                              {card?.links.map((link, linkIdx) => {
                                return (
                                  <p key={linkIdx}>
                                    <a
                                      href={link.url}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {link.label}
                                    </a>
                                    <br />
                                  </p>
                                )
                              })}
                            </div>
                            <hr />
                            {card.detail && (
                              <>
                                {showMoreCard === `condition-card-${idx}` ? (
                                  <>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: `${card.detail}`
                                          ? card.detail
                                          : // card.detail.replace(/<p>Advarsel gjelder:<\/p><ul><li>http:\/\/snomed\.info\/sct\|\d+<\/li><\/ul>/g, '')
                                            "",
                                      }}
                                    ></div>
                                    <Button
                                      onClick={() => setShowMoreCard(undefined)}
                                      style={{
                                        color: "white",
                                        cursor: "pointer",
                                        background: "#619ca5",
                                        border: "#619ca5",
                                      }}
                                    >
                                      Vis mindre
                                    </Button>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: `${card.detail}`
                                          ? card.detail.substring(0, 150)
                                          : // card.detail.replace(/<p>Advarsel gjelder:<\/p><ul><li>http:\/\/snomed\.info\/sct\|\d+<\/li><\/ul>/g, '').substring(0, 150)
                                            "",
                                      }}
                                    ></div>
                                    {/* <div
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          `${card.detail?.substring(0, 150)}...` ??
                                          "",
                                      }}
                                    ></div> */}
                                    <Button
                                      onClick={() =>
                                        setShowMoreCard(`condition-card-${idx}`)
                                      }
                                      style={{
                                        color: "white",
                                        cursor: "pointer",
                                        background: "#619ca5",
                                        border: "#619ca5",
                                      }}
                                    >
                                      Vis mer
                                    </Button>
                                  </>
                                )}
                              </>
                            )}
                          </Card.Body>
                        )
                      } else {
                        return null
                      }
                    })
                  ) : (
                    // using old conteir cqf-server:
                    <Card.Body key={`c-${cdsIdx}-`}>
                      {card.source?.icon && (
                        <img
                          src={card?.source?.icon}
                          height={100}
                          alt={card?.source?.title ?? "source icon"}
                        />
                      )}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h3>{card.summary}</h3>
                        <CircleIcon
                          style={{
                            color:
                              card.indicator === "critical"
                                ? "rgb(204, 0, 0)"
                                : card.indicator === "warning"
                                ? "rgb(204 164 0)"
                                : "black",
                            fontSize: "2em",
                          }}
                        />
                      </div>
                      <div>
                        <b>Kilde: </b>
                        {card.source?.url ? (
                          <a
                            href={card.source?.url}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {card.source?.label}
                          </a>
                        ) : (
                          "Felleskatalogen"
                        )}
                        {card?.links.map((link, linkIdx) => {
                          return (
                            <p key={linkIdx}>
                              <a
                                href={link.url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {link.label}
                              </a>
                              <br />
                            </p>
                          )
                        })}
                      </div>
                      <hr />
                      {card.detail && (
                        <>
                          {showMoreCard === `condition-card-${idx}` ? (
                            <>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: `${card.detail}`
                                    ? card.detail
                                    : // card.detail.replace(/<p>Advarsel gjelder:<\/p><ul><li>http:\/\/snomed\.info\/sct\|\d+<\/li><\/ul>/g, '')
                                      "",
                                }}
                              ></div>
                              <Button
                                onClick={() => setShowMoreCard(undefined)}
                                style={{
                                  color: "white",
                                  cursor: "pointer",
                                  background: "#619ca5",
                                  border: "#619ca5",
                                }}
                              >
                                Vis mindre
                              </Button>
                            </>
                          ) : (
                            <>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: `${card.detail}`
                                    ? card.detail.substring(0, 150)
                                    : // card.detail.replace(/<p>Advarsel gjelder:<\/p><ul><li>http:\/\/snomed\.info\/sct\|\d+<\/li><\/ul>/g, '').substring(0, 150)
                                      "",
                                }}
                              ></div>
                              {/* <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    `${card.detail?.substring(0, 150)}...` ??
                                    "",
                                }}
                              ></div> */}
                              <Button
                                onClick={() =>
                                  setShowMoreCard(`condition-card-${idx}`)
                                }
                                style={{
                                  color: "white",
                                  cursor: "pointer",
                                  background: "#619ca5",
                                  border: "#619ca5",
                                }}
                              >
                                Vis mer
                              </Button>
                            </>
                          )}
                        </>
                      )}
                    </Card.Body>
                  )
                )
              })}
            </Card>
          </FloatingLabel>
        </Container>
      )
    })}
  </Row>
)
