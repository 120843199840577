const CLIENT_ID = process.env.REACT_APP_ENTRA_CLIENT_ID || '179756f6-7d73-4c08-a665-b233b1543b70'
const TENANT_ID = process.env.REACT_APP_ENTRA_TENANT_ID || 'd2d34757-bda2-4a7a-849c-74e8d372b73d'

export const apiSearchScopeAction =
  'https://semanticlab.onmicrosoft.com/semantic-pulse-api/search'

export const msalConfig = {
  auth: {
    clientId: CLIENT_ID,
    authority: `https://login.microsoftonline.com/${TENANT_ID}/v2.0/.well-known/openid-configuration`,
    redirectUri: window.location.origin,
    scopes: ['openid', 'profile', 'email', apiSearchScopeAction]
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  }
}
console.log('msalConfig', msalConfig)


