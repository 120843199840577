import "./index.css"
import "./App.css"
import React /*, { useContext, useEffect, useState } */ from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card"
import { Container } from "react-bootstrap"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { useNavigate /* redirect */ } from "react-router-dom"
// import AuthContext from "../src/context/AuthProvider";
import FloatingLabel from "react-bootstrap/FloatingLabel"
// import { Navigate, Outlet } from "react-router-dom";
//import Logout from "./components/Login/Logout"
import Footer from "./components/Footer"
export default function Home() {
  const navigator = useNavigate()

  // const { auth, setAuth } = useContext(AuthContext);
  // const [state, setState] = useState({loaded: false, success: false});

  // useEffect(() => {
  //   fetch("/isLoggedIn")
  //   .then((data) => data.json())
  //   .then((resp) => {
  //     console.log(resp);
  //     if(resp.authorized) setState({loaded: true, success: true});
  //     else setState({loaded: true, success: false});
  //   });

  // }, [auth]);

  // <CheckIsLoggedIn state={this.props.state}/>

  // if(!this.state.loaded) return <></>;
  // if(!this.state.success) return <Navigate to="/login"/>;
  return (
    <>
      <Container>
        <Row>
          <div className="col-md-10">
            <img
              className="imgLogo"
              src="assets/holder.png"
              alt="Illustration"
            />
            <h1>Elektronisk pasientjournal (EPJ) på FHIR</h1>
            <p>
              Dette er vårt testmiljø for interoperablitet i helstjenesten. Det
              demonstrerer hvordan både pasientdata og kunnskap kan flyte fritt
              mellom ulike kilder.
            </p>
            <p>
              Det visuelle grensesnittet og funksjonelle implementeringer bruker{" "}
              <a href="https://hapifhir.io">HAPI FHIR</a> som back-end.
            </p>
          </div>
          <div className="col-md-2">
            {/*<Logout />*/}
          </div>
        </Row>

        <Row>
          <Col>
            {" "}
            <FloatingLabel>
              <Card className="card1">
                <Card.Body style={{ backgroundColor: " #C3D8E4" }}>
                  <Button
                    style={{ backgroundColor: "#154e78", border: "#154e78" }}
                    className="newPateint"
                    // onClick={() => navigator(`Patient`)}
                    onClick={() => navigator(`/patient`, { replace: true })}
                    // onClick={() => {
                    //   console.log("clicked!");
                    //   return redirect("patient")}
                    // }
                    // onClick={() => {
                    //   return <Navigate to="patient"/>}
                    // }
                  >
                    Finn pasient
                  </Button>
                </Card.Body>
              </Card>
            </FloatingLabel>
          </Col>
          <Col>
            {" "}
            <FloatingLabel>
              <Card className="card2">
                <Card.Body style={{ backgroundColor: "#C3D8E4" }}>
                  <Button
                    className="newPateint"
                    onClick={() =>
                      navigator(`/createPatient`, { replace: true })
                    }
                    style={{ backgroundColor: "#154e78", border: "#154e78" }}
                  >
                    Ny pasient
                  </Button>
                </Card.Body>
              </Card>
            </FloatingLabel>
          </Col>
        </Row>

        <Row className="marginTop">
          <h2>Status</h2>
          <h3>Implementert funksjonalitet</h3>
          <ul>
            <li>Administering av pasient - søke opp og opprette ny</li>
            <li>Knytte problem (condition) til pasient</li>
            <li>
              Knytte forskrivning av legemiddel (medicationRequest) til pasient
            </li>
            <li>Knytte observasjoner og funn (observation) til pasient</li>
            <li>
              Enkel kunnskapsstøtte for virkestoffsforskriving fra
              Felleskatalogen
            </li>
            <li>
              <a href="https://cds-hooks.org">HL7 CDS-hooks</a>
              kunnskaps og -beslutningsstøtte fra Felleskatalogen{" "}
              <ul>
                <li>
                  Varsel ordinering av visse legemidler ved nedsatt nyrefunksjon
                </li>
                <li>
                  Varsel ved ordinering av legemidler med risiko for alvorlig
                  bivirkninger
                </li>
                <li>
                  Varsel ved ordinering av legemidler som bør unngås av personer
                  over 65 år
                </li>
                <li>Oversikt over risikolegemidler ved legemiddelgjennomgang</li>
              </ul>
              <li>Integrasjon med KI</li>
            </li>
          </ul>
          <h3>Dette jobber vi med nå</h3>
          <ul>
            <li>SMART on FHIR-applikasjoner</li>

            <li>Diverse forbedringer</li>
          </ul>
          <p>
            <em>Sist oppdatert: 10. juni 2024</em>
          </p>
        </Row>
      </Container>
      <Footer />
    </>
  )
}
